<template>
	<div class="form-list">
		<el-form ref="_importSaleOrderForm" :model="dataSource">
			<el-form-item>
				<el-col :span="21"> 1、点击<el-button type="text" @click="DownloadFile()"
						style="text-decoration:underline;color:#1874CD;cursor:pointer">这里</el-button>下载导入模板 </el-col>
			</el-form-item>

			<el-form-item>
				<el-col :span="3"><span style="color:red;"></span>2、货主名称：</el-col>
				<el-col :span="17">
					<el-form-item prop="CustomerName">
						<el-select v-model="upLoadData.customerCode" collapse-tags placeholder="请选择">
							<el-option v-for="item in CustomerList" :key="item.Key" :label="item.Value"
								:value="item.Key">
							</el-option>
						</el-select>

					</el-form-item>
				</el-col>
			</el-form-item>
			<el-form-item>
				<!--:beforeUpload="beforeUpload" :action="importAction"-->
				<el-upload class="upload-demo" :action="importAction" :headers="headers" :on-preview="handlePreview"
					:on-remove="handleRemove" :before-remove="beforeRemove" :data="upLoadData" multiple :limit="1"
					:on-exceed="handleExceed" :beforeUpload="beforeUpload" :onSuccess="uploadSuccess"
					:file-list="fileList" accept=".xls,.xlsx" :on-error="uploadError">
					<el-button size="small" type="primary" :loading="loading" :disabled="loading">{{ updateText
						}}</el-button>
					<div slot="tip" class="el-upload__tip" style="overflow-y:auto;"><span v-text="tipmsg"></span></div>
				</el-upload>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import sha from '../../../../scripts/sha.js';
export default {
	data() {
		return {
			importAction: window.AppConfig.apiCustomerUrl + "omsapi/customeraddress/importaddress?currentType=" + this
				.$store.state.global.currentType + "&currentCCode=" + this.$store.state.global.currentCCode,
			tipmsg: "",

			fileList: [],
			upLoadData: {
				addressType: "",
				customerCode: ""
			},
			headers: {
				"X-Authorization": "Bearer " + sha.getToken(),
			},
			loading: false,
			updateText: "导入",
			CustomerList: [],
			CustomerCode: "",

		};
	},
	props: {
		dataSource: {},
		options: {
			addressType: 0,
		}
	},
	watch: {

	},
	mounted() {
		this.upLoadData.addressType = this.options.addressType;
		this.getCustomers();
	},
	methods: {
		getCustomers: function () {
			var _this = this;
			this.$ajax.query("omsapi/customerinfo/getcurrentcustomers", "get", {}, data => {
				_this.CustomerList = data.Result;
			});

		},
		handleRemove(file, fileList) {
			console.log(file, fileList);
		},
		handlePreview(file) {
			console.log(file);
		},
		handleExceed(files, fileList) {
			this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
		},
		beforeRemove(file, fileList) {
			if (file && file.status === "success")
				return this.$confirm(`确定移除 ${file.name}？`);
		},
		// 上传成功后的回调
		uploadSuccess(response, file, fileList) {
			this.uploadTHint(false);
			console.log(response);
			this.tipmsg = response.OperationDesc;
			//this.Utils.messageBox(response.OperationDesc, response.IsSuccess ? "success" : "error");
		},
		// 上传前对文件的大小的判断
		beforeUpload(file) {
			this.uploadTHint(true);
			var extension = file.name.substring(file.name.lastIndexOf('.') + 1);
			var allowExtension = ['xls', 'xlsx'];
			const isLt2M = file.size / 1024 / 1024 < 10;
			if (allowExtension.indexOf(extension.toLowerCase()) < 0) {
				this.Utils.messageBox('上传模板只能是 xls、xlsx格式!', "error");
				this.uploadTHint(false);
				return false;
			}
			if (!isLt2M) {
				this.Utils.messageBox('上传模板大小不能超过 10MB!', "error");
				this.uploadTHint(false);
				return false;
			}
			return extension && isLt2M;
		},
		// 上传错误
		uploadError(response, file, fileList) {
			this.uploadTHint(false);
			this.Utils.messageBox("上传失败，请重试！", "error");
		},
		DownloadFile: function () {
			// this.Utils.export("omsapi/common/downloadtemplate?uploadFileType=700&fileName=地址导入.xlsx");
			this.$ajax.send("omsapi/template/getTemplateInfoByType", "get", {
				ieType: 1, bisType: 9
			}, (data) => {
				if (data.Result != null) {
					this.Utils.exportUrl("omsapi/files/download?file=地址导入模板.xlsx&url="+data.Result.ServerFileName + "&token=" + sha.getToken1());
					//this.Utils.exportExt(data.Result.ServerFileName);
				}
			});
		},
		uploadTHint(value) {
			if (value) {
				this.loading = true;
				this.updateText = "拼命上传中...";
			} else {
				this.loading = false;
				this.updateText = "导入";
			}
		}
	}
}
</script>
<style scope>
input[type="file"] {
	display: none;
}

.el-upload-list {
	width: 200px;
}

.el-select {
	width: 135px;
}
</style>